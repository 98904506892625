jQuery(function($){

    $('.js-trigger-product-finder').submit(function(e) {
        e.preventDefault();

        // first we check if an option is a direct link. if so, let's go
        var direct_link = $('.home-prod-finder option:selected').data('direct-link');
        if(direct_link) {
            window.location.href = direct_link;
        }

        // get the data
        var slide_1_val = $('.home-prod-finder .slide-1 option:selected').val();
        var slide_2_val = $('.home-prod-finder .slide-2 option:selected').val();

        $('.js-product-finder-container').attr('data-slide-1-val', slide_1_val);
        $('.js-product-finder-container').attr('data-slide-2-val', slide_2_val);

        $('.js-product-finder-container').addClass('form-submitted');

        // open the product finder
        $('.js-program-finder-trigger').click();
    });

    $('.js-home-hero-select').on('change', function() {
        var message = $(this).find(':selected').attr('data-home-message');
        var button_text = $(this).find(':selected').attr('data-home-button-text');
        var button_link = $(this).find(':selected').attr('data-home-button-link');
        
        if( message ) {
            $('.js-home-prod-finder-text').html(message);
            
            if(button_text && button_link) {
                $('.js-home-prod-finder-button').html(button_text);
                $('.js-home-prod-finder-button').attr('href', button_link);
            }

            $('.js-home-prod-finder-message').addClass('active');
        } else {
            $('.js-home-prod-finder-message').removeClass('active');
        }
    });

});